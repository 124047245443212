<template>
    <div>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          margin-left: auto;
          max-width: 20rem;
          margin-block: 1rem;
        ">
        <el-input
          placeholder="search by sender ID or phone number"
          class="search_item"
          style="height: 40px !important"
          v-model="search"
          :debounce="700"
          :disabled="isLoading"
          @input="debounceSearch"
          autocomplete="on"
          clearable
          @clear="getFraudulentMessages(1)">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <el-table
        :data="fraudulentMessages.data || fraudulentMessages"
        height="55vh"
        v-loading="isLoading">
        <el-table-column label="Phone" prop="phone"></el-table-column>
        <el-table-column label="Sender ID" prop="sender_id"></el-table-column>
        <el-table-column label="Message Content" prop="content"></el-table-column>
        <el-table-column label="Created At" prop="created_at">
          <template slot-scope="scope">
            {{ moment(scope.row.created_at).format("DD MMM YYYY hh:mm A") }}
          </template>
        </el-table-column>
      </el-table>
      <div class="col-md-12" style="text-align: center; margin-top: 2em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="per_page"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </template>
  
  <script>
    import moment from "moment";
    export default {
      name: "reseller_fraudulent_messages",
      data() {
        return {
          per_page: 50,
          total: 0,
          isLoading: false,
          search: "",
        };
      },
      computed: {
        fraudulentMessages() {
          return (
            this.$store.getters["admin/resellerFraud/fraudulentMessages"] || []
          );
        },
      },
      methods: {
        async getFraudulentMessages(page = 1, search = "") {
          this.isLoading = true;
          try {
            const response = await this.$store.dispatch(
              "admin/resellerFraud/getFraudulentMessages",
              {
                page: page,
                search: search,
              }
            );
            this.total = response.total;
          } catch (error) {
            this.$message.error(error.response.data.message);
          } finally {
            this.isLoading = false;
          }
        },
        handleCurrentChange(val) {
          this.getFraudulentMessages(val);
        },
        debounceSearch: _.debounce(function () {
          this.getFraudulentMessages(1, this.search);
        }, 700),
      },
      mounted() {
        // this.getFraudulentMessages();
        // ! Fetched in index.vue instead
      },
    };
  </script>
  
  <style scoped></style>
  